/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeviceCategoriesDto } from '../models';
// @ts-ignore
import { DeviceTypeListItemDtoPaginatedList } from '../models';
/**
 * DeviceTypeApi - axios parameter creator
 * @export
 */
export const DeviceTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign device type to category.
         * @param {number} deviceTypeId Device type id.
         * @param {DeviceCategoriesDto} [deviceCategoriesDto] Device category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDeviceTypeToCategory: async (deviceTypeId: number, deviceCategoriesDto?: DeviceCategoriesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceTypeId' is not null or undefined
            assertParamExists('assignDeviceTypeToCategory', 'deviceTypeId', deviceTypeId)
            const localVarPath = `/api/DeviceType/{deviceTypeId}`
                .replace(`{${"deviceTypeId"}}`, encodeURIComponent(String(deviceTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceCategoriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filtered paginated device type list.
         * @param {string} [name] 
         * @param {boolean} [isPaired] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypes: async (name?: string, isPaired?: boolean, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DeviceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (isPaired !== undefined) {
                localVarQueryParameter['IsPaired'] = isPaired;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceTypeApi - functional programming interface
 * @export
 */
export const DeviceTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign device type to category.
         * @param {number} deviceTypeId Device type id.
         * @param {DeviceCategoriesDto} [deviceCategoriesDto] Device category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignDeviceTypeToCategory(deviceTypeId: number, deviceCategoriesDto?: DeviceCategoriesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignDeviceTypeToCategory(deviceTypeId, deviceCategoriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filtered paginated device type list.
         * @param {string} [name] 
         * @param {boolean} [isPaired] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypes(name?: string, isPaired?: boolean, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceTypeListItemDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypes(name, isPaired, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceTypeApi - factory interface
 * @export
 */
export const DeviceTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign device type to category.
         * @param {number} deviceTypeId Device type id.
         * @param {DeviceCategoriesDto} [deviceCategoriesDto] Device category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDeviceTypeToCategory(deviceTypeId: number, deviceCategoriesDto?: DeviceCategoriesDto, options?: any): AxiosPromise<void> {
            return localVarFp.assignDeviceTypeToCategory(deviceTypeId, deviceCategoriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filtered paginated device type list.
         * @param {string} [name] 
         * @param {boolean} [isPaired] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypes(name?: string, isPaired?: boolean, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<DeviceTypeListItemDtoPaginatedList> {
            return localVarFp.getDeviceTypes(name, isPaired, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignDeviceTypeToCategory operation in DeviceTypeApi.
 * @export
 * @interface DeviceTypeApiAssignDeviceTypeToCategoryRequest
 */
export interface DeviceTypeApiAssignDeviceTypeToCategoryRequest {
    /**
     * Device type id.
     * @type {number}
     * @memberof DeviceTypeApiAssignDeviceTypeToCategory
     */
    readonly deviceTypeId: number

    /**
     * Device category.
     * @type {DeviceCategoriesDto}
     * @memberof DeviceTypeApiAssignDeviceTypeToCategory
     */
    readonly deviceCategoriesDto?: DeviceCategoriesDto
}

/**
 * Request parameters for getDeviceTypes operation in DeviceTypeApi.
 * @export
 * @interface DeviceTypeApiGetDeviceTypesRequest
 */
export interface DeviceTypeApiGetDeviceTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceTypeApiGetDeviceTypes
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof DeviceTypeApiGetDeviceTypes
     */
    readonly isPaired?: boolean

    /**
     * 
     * @type {number}
     * @memberof DeviceTypeApiGetDeviceTypes
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceTypeApiGetDeviceTypes
     */
    readonly take?: number

    /**
     * 
     * @type {string}
     * @memberof DeviceTypeApiGetDeviceTypes
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof DeviceTypeApiGetDeviceTypes
     */
    readonly orderByAsc?: boolean
}

/**
 * DeviceTypeApi - object-oriented interface
 * @export
 * @class DeviceTypeApi
 * @extends {BaseAPI}
 */
export class DeviceTypeApi extends BaseAPI {
    /**
     * 
     * @summary Assign device type to category.
     * @param {DeviceTypeApiAssignDeviceTypeToCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public assignDeviceTypeToCategory(requestParameters: DeviceTypeApiAssignDeviceTypeToCategoryRequest, options?: any) {
        return DeviceTypeApiFp(this.configuration).assignDeviceTypeToCategory(requestParameters.deviceTypeId, requestParameters.deviceCategoriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filtered paginated device type list.
     * @param {DeviceTypeApiGetDeviceTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public getDeviceTypes(requestParameters: DeviceTypeApiGetDeviceTypesRequest = {}, options?: any) {
        return DeviceTypeApiFp(this.configuration).getDeviceTypes(requestParameters.name, requestParameters.isPaired, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }
}
