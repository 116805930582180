import { createModel } from '@rematch/core'
import { api } from '@/api'
import { ContactApiGetContractContactsRequest, ContactApiGetCustomerContactsRequest, ContactDto } from '@/api/generated'
import type { RootModel } from '.'

type ContactState = {
  contacts?: ContactDto[]
}

export const contact = createModel<RootModel>()({
  state: {} as ContactState,
  reducers: {
    setContacts(state, payload: ContactDto[]) {
      state.contacts = payload
    }
  },
  effects: dispatch => {
    const { contact } = dispatch

    return {
      async getContractContacts(requestParameters: ContactApiGetContractContactsRequest): Promise<void> {
        const { data } = await api.contact.getContractContacts({ ...requestParameters })

        contact.setContacts(data)
      },

      async getCustomerContacts(requestParameters: ContactApiGetCustomerContactsRequest): Promise<void> {
        console.log('get')
        const { data } = await api.contact.getCustomerContacts({ ...requestParameters })

        contact.setContacts(data)
      }
    }
  }
})
