import { message } from 'antd'
import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import {
  AdminDeviceListDto,
  ContractDeviceListDto,
  ControlPanelCardDto,
  DeviceApiAssignDeviceToContractRequest,
  DeviceApiDeviceTotalCountsRequest,
  DeviceApiDismountRequest,
  DeviceApiFavourizeDeviceRequest,
  DeviceApiGetDevicesForContractRequest,
  DeviceApiGetDevicesRequest,
  DeviceApiUnassignContractRequest,
  DeviceApiUnfavourizeDeviceRequest,
  DeviceApiUpdateDeviceNameRequest,
  DeviceCategories,
  DeviceCount,
  MeasurementDataDto
} from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import i18n from '@/i18n'
import type { RootModel } from '.'

type DeviceListRequestParams = ListRequestParams & {
  deviceCategory?: DeviceCategories
  isPaired?: boolean
}

interface DeviceApiGetDevicesRequestParams extends Omit<DeviceApiGetDevicesRequest, 'deviceCategory'> {
  deviceCategory?: DeviceCategories
}

type DeviceState = {
  devices?: AdminDeviceListDto[]
  deviceListParams?: ListRequestParams
  previousListParams?: DeviceListRequestParams
  totalCounts?: DeviceCount[]
  hasUnpaired?: boolean
  device?: AdminDeviceListDto
  deviceMeasurementData?: MeasurementDataDto
  contractDeviceList?: ContractDeviceListDto[]
  contractDeviceListParams?: ListRequestParams
  favouriteDevices?: ControlPanelCardDto[]
}

export const device = createModel<RootModel>()({
  state: {} as DeviceState,
  reducers: {
    reset(state) {
      state.devices = []
      state.deviceListParams = {}
      state.previousListParams = {}
      state.deviceMeasurementData = {}
      state.contractDeviceList = []
      state.contractDeviceListParams = {}
      state.favouriteDevices = []
    },
    resetDevice(state) {
      state.device = {}
    },
    setPreviousListParams(state, listParams: DeviceListRequestParams) {
      state.previousListParams = listParams
    },
    setDevices(state, payload: { devices: AdminDeviceListDto[]; listParams: ListRequestParams }) {
      state.devices = payload.devices
      state.deviceListParams = payload.listParams
    },
    setTotalCounts(state, totalCounts: DeviceCount[]) {
      state.totalCounts = totalCounts
    },
    setHasUnpaired(state, hasUnpaired?: boolean) {
      state.hasUnpaired = hasUnpaired
    },
    setDevice(state, payload: AdminDeviceListDto) {
      state.device = payload
    },
    setDeviceMeasurementData(state, payload: MeasurementDataDto) {
      state.deviceMeasurementData = payload
    },
    setContractDeviceList(
      state,
      payload: { contractDeviceList: ContractDeviceListDto[]; listParams: ListRequestParams }
    ) {
      state.contractDeviceList = payload.contractDeviceList
      state.contractDeviceListParams = payload.listParams
    },
    setControlPanelCards(state, controlPanelCards: ControlPanelCardDto[]) {
      state.favouriteDevices = controlPanelCards
    }
  },
  effects: dispatch => {
    const { device } = dispatch

    return {
      async getDevices(
        params: DeviceApiGetDevicesRequestParams = {},
        state: RematchRootState<RootModel>
      ): Promise<void> {
        const { isPaired, ...rest } = params
        const requestParams = {
          ...state.device.deviceListParams,
          ...rest,
          isPaired: isPaired === false ? false : undefined,
          deviceCategory: params?.deviceCategory ? [params.deviceCategory] : undefined
        }

        const {
          data: { result, ...listParams }
        } = await api.device.getDevices(requestParams)

        device.setDevices({ devices: result || [], listParams })
        device.setPreviousListParams({
          ...requestParams,
          ...listParams,
          isPaired,
          deviceCategory: requestParams?.deviceCategory?.length ? requestParams.deviceCategory[0] : undefined
        })
      },

      async getDevice(id: number): Promise<void> {
        const { data } = await api.device.getDeviceById({ id })
        device.setDevice(data)
      },

      async getDeviceMeasurement(id: number): Promise<void> {
        const { data } = await api.device.getMeasurementData({ id })
        device.setDeviceMeasurementData(data)
      },

      async getTotalCounts(requestParameters?: DeviceApiDeviceTotalCountsRequest): Promise<void> {
        const { data } = await api.device.deviceTotalCounts(requestParameters)
        device.setTotalCounts(data)
      },

      async hasUnpairedDevice(): Promise<void> {
        const { data } = await api.device.anyUnpairedDevice()
        device.setHasUnpaired(data.value)
      },

      async assignDevice({ deviceId, deviceAssignDto }: DeviceApiAssignDeviceToContractRequest): Promise<void> {
        await api.device.assignDeviceToContract({ deviceId, deviceAssignDto })
      },

      async unAssignDevice({ deviceId }: DeviceApiUnassignContractRequest): Promise<void> {
        await api.device.unassignContract({ deviceId })
      },

      async getContractDeviceList(
        params: DeviceApiGetDevicesForContractRequest,
        state: RematchRootState<RootModel>
      ): Promise<void> {
        const requestParams = {
          ...state.device.contractDeviceListParams,
          ...params
        }

        const {
          data: { result, ...listParams }
        } = await api.device.getDevicesForContract(requestParams)

        device.setContractDeviceList({ contractDeviceList: result || [], listParams })
      },

      async syncDevices(): Promise<void> {
        await api.device
          .deviceSync()
          .then(() => {
            message.success(i18n.t('devices.sync-success'))
          })
          .catch(() => {
            message.error(i18n.t('devices.sync-error'), 5)
          })
      },

      async updateDeviceName({ deviceId, deviceNameDto }: DeviceApiUpdateDeviceNameRequest): Promise<void> {
        await api.device.updateDeviceName({ deviceId, deviceNameDto })
      },

      async getFavouriteDevices(): Promise<void> {
        const { data } = await api.controlPanel.getAllControlPanel()
        device.setControlPanelCards(data)
      },

      async favourizeDevice({ deviceId }: DeviceApiFavourizeDeviceRequest): Promise<void> {
        await api.device.favourizeDevice({ deviceId })
      },

      async unfavourizeDevice({ deviceId }: DeviceApiUnfavourizeDeviceRequest): Promise<void> {
        await api.device.unfavourizeDevice({ deviceId })
      },

      async dismountDevice({ deviceId, dismountDto }: DeviceApiDismountRequest): Promise<void> {
        await api.device.dismount({ deviceId, dismountDto })
      }
    }
  }
})
