import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Row, Space, Typography } from 'antd'
import { mdiEarth, mdiEmailOutline, mdiFacebook, mdiInstagram, mdiPhone } from '@mdi/js'
import classNames from 'classnames'
import LogoWhite from '@/assets/images/logo_horizontal-white.svg'
import SimpleLogo from '@/assets/images/simple_logo.png'
import MaterialIcon from '@/components/icon/MaterialIcon'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { useDispatch, useSelector } from '@/store'
import Loading from '../../Loading'
import styles from './LayoutFooter.module.scss'

const LayoutFooter: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSmall = useBreakpoint()
  const { contacts } = useSelector(state => state.encontact)
  const { loading } = useSelector(state => state.loading.effects.encontact.getContact)

  useEffect(() => {
    if (!contacts) {
      dispatch.encontact.getContact()
    }
  }, [dispatch])

  return (
    <div
      className={classNames(styles.footer, {
        [styles.footerMobile]: isSmall
      })}
    >
      <Row align="middle" gutter={[32, 12]}>
        <Col xs={24} lg={6}>
          <img
            src={LogoWhite}
            className={classNames(styles.footerLogo, {
              [styles.footerLogoMobile]: isSmall
            })}
          />
        </Col>

        {loading ? (
          <Col lg={12}>
            <Loading />
          </Col>
        ) : (
          <>
            {(contacts?.address || contacts?.phone || contacts?.email) && (
              <>
                <Col xs={24} md={12} lg={6}>
                  <Space direction="vertical">
                    {contacts?.phone && (
                      <a href={`tel:${contacts?.phone}`}>
                        <MaterialIcon path={mdiPhone} className={styles.footerIcon} />
                        <Typography.Text underline>{contacts?.phone}</Typography.Text>
                      </a>
                    )}

                    {contacts?.email && (
                      <a href={`mailto:${contacts?.email}`}>
                        <MaterialIcon path={mdiEmailOutline} className={styles.footerIcon} />
                        <Typography.Text underline>{t('footer.email')}</Typography.Text>
                      </a>
                    )}

                    {contacts?.addressLink && (
                      <a href={contacts?.addressLink} target="_blank" rel="noreferrer">
                        <MaterialIcon path={mdiEarth} className={styles.footerIcon} />
                        <Typography.Text underline>{contacts?.address}</Typography.Text>
                      </a>
                    )}
                  </Space>
                </Col>
              </>
            )}
            <Col xs={12} md={12} lg={3}>
              <Space direction="vertical">
                <Link to="">
                  <Typography.Text underline>{t('footer.about')}</Typography.Text>
                </Link>

                <Link to="">
                  <Typography.Text underline>{t('footer.advanteges')}</Typography.Text>
                </Link>
              </Space>
            </Col>
            <Col xs={12} md={12} lg={3}>
              <Space direction="vertical">
                <Link to="">
                  <Typography.Text underline>{t('footer.how-it-works')}</Typography.Text>
                </Link>

                <Link to="">
                  <Typography.Text underline>{t('footer.faq')}</Typography.Text>
                </Link>
              </Space>
            </Col>
            {(contacts?.facebook || contacts?.instagram) && (
              <Col xs={24} md={12} lg={3}>
                <Space direction="vertical">
                  <Typography.Text>{t('footer.follow-us')}</Typography.Text>

                  <Space direction="horizontal" size="middle">
                    {contacts?.facebook && (
                      <a href={contacts?.facebook} target="_blank" rel="noreferrer">
                        <MaterialIcon path={mdiFacebook} className={styles.socialIcon} size={1.5} />
                      </a>
                    )}

                    {contacts?.instagram && (
                      <a href={contacts?.instagram} target="_blank" rel="noreferrer">
                        <MaterialIcon path={mdiInstagram} className={styles.socialIcon} size={1.5} />
                      </a>
                    )}
                  </Space>
                </Space>
              </Col>
            )}

            <Col xs={24} md={12} lg={3}>
              <a href={t('simple-url')} target="_blank" rel="noreferrer">
                <img src={SimpleLogo} className={styles.simpleLogo} />
              </a>
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}

export default LayoutFooter
