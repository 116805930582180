import { FC, ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Layout, Row } from 'antd'
import { mdiChevronDown, mdiMenu } from '@mdi/js'
import classNames from 'classnames'
import Logo from '@/assets/images/logo_horizontal-white.svg'
import MaterialIcon from '@/components//icon/MaterialIcon'
import { VerticalDivider } from '@/components/verticalDivider/VerticalDivider'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import useInterval from '@/hooks/useInterval'
import { Role } from '@/models/role'
import { useDispatch, useSelector } from '@/store'
import CustomDropdown from '../customDropdown/CustomDropdown'
import CustomErrorHandler from '../customErrorHandler/CustomErrorHandler'
import DrawerMenu from './components/DrawerMenu/DrawerMenu'
import LayoutFooter from './components/LayoutFooter/LayoutFooter'
import TopMenu from './components/TopMenu/TopMenu'
import UserMenu from './components/UserMenu/UserMenu'
import styles from './PrivateLayout.module.scss'

interface PrivateLayoutProps {
  name?: string
  children: ReactElement
}

const PrivateLayout: FC<PrivateLayoutProps> = ({ children }) => {
  const dispatch = useDispatch()
  const isLarge = useBreakpoint({ min: 'md' })
  const isSmall = useBreakpoint()
  const { user } = useSelector(state => state.auth)
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [userMenuVisible, setUserMenuVisible] = useState<boolean>(false)

  const handleMenuClick = (): void => {
    setUserMenuVisible(false)
  }

  useInterval(() => {
    if (user?.role === Role.Admin) {
      dispatch.device.hasUnpairedDevice()
    }
  }, 300000) //5 min

  useEffect(() => {
    if (user?.role === Role.Admin) {
      dispatch.device.hasUnpairedDevice()
    }
  }, [dispatch])

  return (
    <Layout
      className={classNames(styles.privateLayout, {
        [styles.privateLayoutSmall]: !isLarge
      })}
    >
      <CustomErrorHandler />
      <Layout.Header className={styles.header}>
        <Row wrap={false}>
          {isLarge ? (
            <>
              <Col flex="0 0 10rem">
                <Link to="/">
                  <img src={Logo} className={styles.logo} />
                </Link>
              </Col>
              <Col flex="auto">
                <TopMenu />
              </Col>
              <VerticalDivider className={styles.divider} />
              <Col flex="0 1 auto">
                <CustomDropdown
                  overlay={<UserMenu handleMenuClick={handleMenuClick} />}
                  trigger={['click']}
                  placement="bottomRight"
                  visible={userMenuVisible}
                  onVisibleChange={setUserMenuVisible}
                  overlayClassName={styles.userMenuDropdown}
                >
                  <Button type="link" className={styles.userMenuButton}>
                    {`${user?.lastName} ${user?.firstName}`}
                    <MaterialIcon path={mdiChevronDown} className={styles.userMenuIcon} />
                  </Button>
                </CustomDropdown>
              </Col>
            </>
          ) : (
            <>
              <Col flex={'38px'}>
                <Button
                  type="link"
                  icon={<MaterialIcon path={mdiMenu} />}
                  onClick={() => setDrawerVisible(!drawerVisible)}
                  className={styles.hamburgerBtn}
                />

                <DrawerMenu visible={drawerVisible} onClose={() => setDrawerVisible(false)} />
              </Col>
              <Col flex="auto">
                <Link to="/">
                  <img
                    src={Logo}
                    className={classNames(styles.logo, {
                      [styles.logoSmall]: !isLarge
                    })}
                  />
                </Link>
              </Col>
            </>
          )}
        </Row>
      </Layout.Header>

      <Layout.Content className={styles.layoutContent}>
        <div
          className={classNames({
            [styles.content]: !isSmall
          })}
        >
          {children}
        </div>

        {user?.role === Role.User && <LayoutFooter />}
      </Layout.Content>
    </Layout>
  )
}

export default PrivateLayout
