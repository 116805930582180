/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuditLogDtoPaginatedList } from '../models';
/**
 * AuditLogApi - axios parameter creator
 * @export
 */
export const AuditLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get filtered paginated auditlog list.
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {string} [tablePk] 
         * @param {string} [tableName] 
         * @param {string} [action] 
         * @param {string} [functionGroup] 
         * @param {string} [functionName] 
         * @param {string} [auditUser] 
         * @param {string} [auditUserId] 
         * @param {Array<string>} [createdAt] 
         * @param {string} [ipAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditLog: async (skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, tablePk?: string, tableName?: string, action?: string, functionGroup?: string, functionName?: string, auditUser?: string, auditUserId?: string, createdAt?: Array<string>, ipAddress?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AuditLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }

            if (tablePk !== undefined) {
                localVarQueryParameter['TablePk'] = tablePk;
            }

            if (tableName !== undefined) {
                localVarQueryParameter['TableName'] = tableName;
            }

            if (action !== undefined) {
                localVarQueryParameter['Action'] = action;
            }

            if (functionGroup !== undefined) {
                localVarQueryParameter['FunctionGroup'] = functionGroup;
            }

            if (functionName !== undefined) {
                localVarQueryParameter['FunctionName'] = functionName;
            }

            if (auditUser !== undefined) {
                localVarQueryParameter['AuditUser'] = auditUser;
            }

            if (auditUserId !== undefined) {
                localVarQueryParameter['AuditUserId'] = auditUserId;
            }

            if (createdAt) {
                localVarQueryParameter['CreatedAt'] = createdAt;
            }

            if (ipAddress !== undefined) {
                localVarQueryParameter['IpAddress'] = ipAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApi - functional programming interface
 * @export
 */
export const AuditLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get filtered paginated auditlog list.
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {string} [tablePk] 
         * @param {string} [tableName] 
         * @param {string} [action] 
         * @param {string} [functionGroup] 
         * @param {string} [functionName] 
         * @param {string} [auditUser] 
         * @param {string} [auditUserId] 
         * @param {Array<string>} [createdAt] 
         * @param {string} [ipAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuditLog(skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, tablePk?: string, tableName?: string, action?: string, functionGroup?: string, functionName?: string, auditUser?: string, auditUserId?: string, createdAt?: Array<string>, ipAddress?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuditLog(skip, take, orderBy, orderByAsc, tablePk, tableName, action, functionGroup, functionName, auditUser, auditUserId, createdAt, ipAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogApi - factory interface
 * @export
 */
export const AuditLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Get filtered paginated auditlog list.
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {string} [tablePk] 
         * @param {string} [tableName] 
         * @param {string} [action] 
         * @param {string} [functionGroup] 
         * @param {string} [functionName] 
         * @param {string} [auditUser] 
         * @param {string} [auditUserId] 
         * @param {Array<string>} [createdAt] 
         * @param {string} [ipAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditLog(skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, tablePk?: string, tableName?: string, action?: string, functionGroup?: string, functionName?: string, auditUser?: string, auditUserId?: string, createdAt?: Array<string>, ipAddress?: string, options?: any): AxiosPromise<AuditLogDtoPaginatedList> {
            return localVarFp.getAllAuditLog(skip, take, orderBy, orderByAsc, tablePk, tableName, action, functionGroup, functionName, auditUser, auditUserId, createdAt, ipAddress, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllAuditLog operation in AuditLogApi.
 * @export
 * @interface AuditLogApiGetAllAuditLogRequest
 */
export interface AuditLogApiGetAllAuditLogRequest {
    /**
     * 
     * @type {number}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly take?: number

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly orderByAsc?: boolean

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly tablePk?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly tableName?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly action?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly functionGroup?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly functionName?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly auditUser?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly auditUserId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly createdAt?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiGetAllAuditLog
     */
    readonly ipAddress?: string
}

/**
 * AuditLogApi - object-oriented interface
 * @export
 * @class AuditLogApi
 * @extends {BaseAPI}
 */
export class AuditLogApi extends BaseAPI {
    /**
     * 
     * @summary Get filtered paginated auditlog list.
     * @param {AuditLogApiGetAllAuditLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public getAllAuditLog(requestParameters: AuditLogApiGetAllAuditLogRequest = {}, options?: any) {
        return AuditLogApiFp(this.configuration).getAllAuditLog(requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, requestParameters.tablePk, requestParameters.tableName, requestParameters.action, requestParameters.functionGroup, requestParameters.functionName, requestParameters.auditUser, requestParameters.auditUserId, requestParameters.createdAt, requestParameters.ipAddress, options).then((request) => request(this.axios, this.basePath));
    }
}
