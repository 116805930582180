/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCompanyCustomerDto } from '../models';
// @ts-ignore
import { CreateIndividualCustomerDto } from '../models';
// @ts-ignore
import { CustomerAdminGridDtoPaginatedList } from '../models';
// @ts-ignore
import { CustomerDetailsDto } from '../models';
// @ts-ignore
import { CustomerNotificationSettingsDto } from '../models';
// @ts-ignore
import { CustomerTotalCountsDto } from '../models';
// @ts-ignore
import { CustomerTypes } from '../models';
// @ts-ignore
import { IdResponseDto } from '../models';
// @ts-ignore
import { StatusHistoryDto } from '../models';
// @ts-ignore
import { UpdateCompanyCustomerDto } from '../models';
// @ts-ignore
import { UpdateCustomerNotificationSettingsDto } from '../models';
// @ts-ignore
import { UpdateIndividualCustomerDto } from '../models';
// @ts-ignore
import { UserRegistrationStates } from '../models';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive customer.
         * @param {number} customerId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCustomer: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('archiveCustomer', 'customerId', customerId)
            const localVarPath = `/api/Customer/{customerId}/Archive`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create company customer.
         * @param {CreateCompanyCustomerDto} [createCompanyCustomerDto] Customer datas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCustomer: async (createCompanyCustomerDto?: CreateCompanyCustomerDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create individual customer.
         * @param {CreateIndividualCustomerDto} [createIndividualCustomerDto] Individual customer data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualCustomer: async (createIndividualCustomerDto?: CreateIndividualCustomerDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Individual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIndividualCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get individual, company, archive customer  total clunts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTotalCounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/TotalCounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by id.
         * @param {number} id Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/api/Customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer notification settings.
         * @param {number} id Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerNotificationSetting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerNotificationSetting', 'id', id)
            const localVarPath = `/api/Customer/{id}/NotificationSettings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer notification settings for customer user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerNotificationSettingForCustomer: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/NotificationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filtered paginated customer list.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [isActive] 
         * @param {number} [contractCount] 
         * @param {number} [activeContractCount] 
         * @param {string} [taxNumber] 
         * @param {CustomerTypes} [customerType] 
         * @param {string} [address] 
         * @param {Array<string>} [birthday] 
         * @param {string} [placeOfBorn] 
         * @param {string} [mothersName] 
         * @param {boolean} [isArchived] 
         * @param {Array<UserRegistrationStates>} [registrationState] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (id?: number, name?: string, isActive?: boolean, contractCount?: number, activeContractCount?: number, taxNumber?: string, customerType?: CustomerTypes, address?: string, birthday?: Array<string>, placeOfBorn?: string, mothersName?: string, isArchived?: boolean, registrationState?: Array<UserRegistrationStates>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (contractCount !== undefined) {
                localVarQueryParameter['ContractCount'] = contractCount;
            }

            if (activeContractCount !== undefined) {
                localVarQueryParameter['ActiveContractCount'] = activeContractCount;
            }

            if (taxNumber !== undefined) {
                localVarQueryParameter['TaxNumber'] = taxNumber;
            }

            if (customerType !== undefined) {
                localVarQueryParameter['CustomerType'] = customerType;
            }

            if (address !== undefined) {
                localVarQueryParameter['Address'] = address;
            }

            if (birthday) {
                localVarQueryParameter['Birthday'] = birthday;
            }

            if (placeOfBorn !== undefined) {
                localVarQueryParameter['PlaceOfBorn'] = placeOfBorn;
            }

            if (mothersName !== undefined) {
                localVarQueryParameter['MothersName'] = mothersName;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (registrationState) {
                localVarQueryParameter['RegistrationState'] = registrationState;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get status history.
         * @param {string} userId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusHistory: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getStatusHistory', 'userId', userId)
            const localVarPath = `/api/Customer/{userId}/StatusHistory`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reacvivate customer.
         * @param {number} customerId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateCustomer: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('reactivateCustomer', 'customerId', customerId)
            const localVarPath = `/api/Customer/{customerId}/Reactivate`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer.
         * @param {number} id Record Id.
         * @param {UpdateCompanyCustomerDto} [updateCompanyCustomerDto] Customer for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCustomer: async (id: number, updateCompanyCustomerDto?: UpdateCompanyCustomerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyCustomer', 'id', id)
            const localVarPath = `/api/Customer/Company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification settings.
         * @param {number} id Customer Id.
         * @param {UpdateCustomerNotificationSettingsDto} [updateCustomerNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerNotificationSetting: async (id: number, updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerNotificationSetting', 'id', id)
            const localVarPath = `/api/Customer/{id}/NotificationSettings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerNotificationSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification settings for customer  users.
         * @param {UpdateCustomerNotificationSettingsDto} [updateCustomerNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerNotificationSettingForCustomer: async (updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/NotificationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerNotificationSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer.
         * @param {number} id Record Id.
         * @param {UpdateIndividualCustomerDto} [updateIndividualCustomerDto] Individual customer data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualCustomer: async (id: number, updateIndividualCustomerDto?: UpdateIndividualCustomerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIndividualCustomer', 'id', id)
            const localVarPath = `/api/Customer/Individual/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndividualCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive customer.
         * @param {number} customerId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCustomer(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create company customer.
         * @param {CreateCompanyCustomerDto} [createCompanyCustomerDto] Customer datas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyCustomer(createCompanyCustomerDto?: CreateCompanyCustomerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyCustomer(createCompanyCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create individual customer.
         * @param {CreateIndividualCustomerDto} [createIndividualCustomerDto] Individual customer data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIndividualCustomer(createIndividualCustomerDto?: CreateIndividualCustomerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIndividualCustomer(createIndividualCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get individual, company, archive customer  total clunts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerTotalCounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTotalCountsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerTotalCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer by id.
         * @param {number} id Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer notification settings.
         * @param {number} id Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerNotificationSetting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNotificationSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerNotificationSetting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer notification settings for customer user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerNotificationSettingForCustomer(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNotificationSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerNotificationSettingForCustomer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filtered paginated customer list.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [isActive] 
         * @param {number} [contractCount] 
         * @param {number} [activeContractCount] 
         * @param {string} [taxNumber] 
         * @param {CustomerTypes} [customerType] 
         * @param {string} [address] 
         * @param {Array<string>} [birthday] 
         * @param {string} [placeOfBorn] 
         * @param {string} [mothersName] 
         * @param {boolean} [isArchived] 
         * @param {Array<UserRegistrationStates>} [registrationState] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(id?: number, name?: string, isActive?: boolean, contractCount?: number, activeContractCount?: number, taxNumber?: string, customerType?: CustomerTypes, address?: string, birthday?: Array<string>, placeOfBorn?: string, mothersName?: string, isArchived?: boolean, registrationState?: Array<UserRegistrationStates>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAdminGridDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(id, name, isActive, contractCount, activeContractCount, taxNumber, customerType, address, birthday, placeOfBorn, mothersName, isArchived, registrationState, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get status history.
         * @param {string} userId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusHistory(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusHistory(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reacvivate customer.
         * @param {number} customerId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivateCustomer(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactivateCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer.
         * @param {number} id Record Id.
         * @param {UpdateCompanyCustomerDto} [updateCompanyCustomerDto] Customer for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCustomer(id: number, updateCompanyCustomerDto?: UpdateCompanyCustomerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCustomer(id, updateCompanyCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update notification settings.
         * @param {number} id Customer Id.
         * @param {UpdateCustomerNotificationSettingsDto} [updateCustomerNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerNotificationSetting(id: number, updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerNotificationSetting(id, updateCustomerNotificationSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update notification settings for customer  users.
         * @param {UpdateCustomerNotificationSettingsDto} [updateCustomerNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerNotificationSettingForCustomer(updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerNotificationSettingForCustomer(updateCustomerNotificationSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer.
         * @param {number} id Record Id.
         * @param {UpdateIndividualCustomerDto} [updateIndividualCustomerDto] Individual customer data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIndividualCustomer(id: number, updateIndividualCustomerDto?: UpdateIndividualCustomerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIndividualCustomer(id, updateIndividualCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive customer.
         * @param {number} customerId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCustomer(customerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.archiveCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create company customer.
         * @param {CreateCompanyCustomerDto} [createCompanyCustomerDto] Customer datas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCustomer(createCompanyCustomerDto?: CreateCompanyCustomerDto, options?: any): AxiosPromise<IdResponseDto> {
            return localVarFp.createCompanyCustomer(createCompanyCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create individual customer.
         * @param {CreateIndividualCustomerDto} [createIndividualCustomerDto] Individual customer data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualCustomer(createIndividualCustomerDto?: CreateIndividualCustomerDto, options?: any): AxiosPromise<IdResponseDto> {
            return localVarFp.createIndividualCustomer(createIndividualCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get individual, company, archive customer  total clunts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerTotalCounts(options?: any): AxiosPromise<CustomerTotalCountsDto> {
            return localVarFp.customerTotalCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by id.
         * @param {number} id Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: number, options?: any): AxiosPromise<CustomerDetailsDto> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer notification settings.
         * @param {number} id Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerNotificationSetting(id: number, options?: any): AxiosPromise<CustomerNotificationSettingsDto> {
            return localVarFp.getCustomerNotificationSetting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer notification settings for customer user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerNotificationSettingForCustomer(options?: any): AxiosPromise<CustomerNotificationSettingsDto> {
            return localVarFp.getCustomerNotificationSettingForCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filtered paginated customer list.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [isActive] 
         * @param {number} [contractCount] 
         * @param {number} [activeContractCount] 
         * @param {string} [taxNumber] 
         * @param {CustomerTypes} [customerType] 
         * @param {string} [address] 
         * @param {Array<string>} [birthday] 
         * @param {string} [placeOfBorn] 
         * @param {string} [mothersName] 
         * @param {boolean} [isArchived] 
         * @param {Array<UserRegistrationStates>} [registrationState] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(id?: number, name?: string, isActive?: boolean, contractCount?: number, activeContractCount?: number, taxNumber?: string, customerType?: CustomerTypes, address?: string, birthday?: Array<string>, placeOfBorn?: string, mothersName?: string, isArchived?: boolean, registrationState?: Array<UserRegistrationStates>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<CustomerAdminGridDtoPaginatedList> {
            return localVarFp.getCustomers(id, name, isActive, contractCount, activeContractCount, taxNumber, customerType, address, birthday, placeOfBorn, mothersName, isArchived, registrationState, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get status history.
         * @param {string} userId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusHistory(userId: string, options?: any): AxiosPromise<Array<StatusHistoryDto>> {
            return localVarFp.getStatusHistory(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reacvivate customer.
         * @param {number} customerId Customer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateCustomer(customerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.reactivateCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer.
         * @param {number} id Record Id.
         * @param {UpdateCompanyCustomerDto} [updateCompanyCustomerDto] Customer for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCustomer(id: number, updateCompanyCustomerDto?: UpdateCompanyCustomerDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompanyCustomer(id, updateCompanyCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification settings.
         * @param {number} id Customer Id.
         * @param {UpdateCustomerNotificationSettingsDto} [updateCustomerNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerNotificationSetting(id: number, updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerNotificationSetting(id, updateCustomerNotificationSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification settings for customer  users.
         * @param {UpdateCustomerNotificationSettingsDto} [updateCustomerNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerNotificationSettingForCustomer(updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerNotificationSettingForCustomer(updateCustomerNotificationSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer.
         * @param {number} id Record Id.
         * @param {UpdateIndividualCustomerDto} [updateIndividualCustomerDto] Individual customer data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualCustomer(id: number, updateIndividualCustomerDto?: UpdateIndividualCustomerDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateIndividualCustomer(id, updateIndividualCustomerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for archiveCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiArchiveCustomerRequest
 */
export interface CustomerApiArchiveCustomerRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof CustomerApiArchiveCustomer
     */
    readonly customerId: number
}

/**
 * Request parameters for createCompanyCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiCreateCompanyCustomerRequest
 */
export interface CustomerApiCreateCompanyCustomerRequest {
    /**
     * Customer datas.
     * @type {CreateCompanyCustomerDto}
     * @memberof CustomerApiCreateCompanyCustomer
     */
    readonly createCompanyCustomerDto?: CreateCompanyCustomerDto
}

/**
 * Request parameters for createIndividualCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiCreateIndividualCustomerRequest
 */
export interface CustomerApiCreateIndividualCustomerRequest {
    /**
     * Individual customer data.
     * @type {CreateIndividualCustomerDto}
     * @memberof CustomerApiCreateIndividualCustomer
     */
    readonly createIndividualCustomerDto?: CreateIndividualCustomerDto
}

/**
 * Request parameters for getById operation in CustomerApi.
 * @export
 * @interface CustomerApiGetByIdRequest
 */
export interface CustomerApiGetByIdRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof CustomerApiGetById
     */
    readonly id: number
}

/**
 * Request parameters for getCustomerNotificationSetting operation in CustomerApi.
 * @export
 * @interface CustomerApiGetCustomerNotificationSettingRequest
 */
export interface CustomerApiGetCustomerNotificationSettingRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof CustomerApiGetCustomerNotificationSetting
     */
    readonly id: number
}

/**
 * Request parameters for getCustomers operation in CustomerApi.
 * @export
 * @interface CustomerApiGetCustomersRequest
 */
export interface CustomerApiGetCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomerApiGetCustomers
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly contractCount?: number

    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly activeContractCount?: number

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly taxNumber?: string

    /**
     * 
     * @type {CustomerTypes}
     * @memberof CustomerApiGetCustomers
     */
    readonly customerType?: CustomerTypes

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly address?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerApiGetCustomers
     */
    readonly birthday?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly placeOfBorn?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly mothersName?: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomerApiGetCustomers
     */
    readonly isArchived?: boolean

    /**
     * 
     * @type {Array<UserRegistrationStates>}
     * @memberof CustomerApiGetCustomers
     */
    readonly registrationState?: Array<UserRegistrationStates>

    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly take?: number

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomerApiGetCustomers
     */
    readonly orderByAsc?: boolean
}

/**
 * Request parameters for getStatusHistory operation in CustomerApi.
 * @export
 * @interface CustomerApiGetStatusHistoryRequest
 */
export interface CustomerApiGetStatusHistoryRequest {
    /**
     * Customer id.
     * @type {string}
     * @memberof CustomerApiGetStatusHistory
     */
    readonly userId: string
}

/**
 * Request parameters for reactivateCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiReactivateCustomerRequest
 */
export interface CustomerApiReactivateCustomerRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof CustomerApiReactivateCustomer
     */
    readonly customerId: number
}

/**
 * Request parameters for updateCompanyCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCompanyCustomerRequest
 */
export interface CustomerApiUpdateCompanyCustomerRequest {
    /**
     * Record Id.
     * @type {number}
     * @memberof CustomerApiUpdateCompanyCustomer
     */
    readonly id: number

    /**
     * Customer for update.
     * @type {UpdateCompanyCustomerDto}
     * @memberof CustomerApiUpdateCompanyCustomer
     */
    readonly updateCompanyCustomerDto?: UpdateCompanyCustomerDto
}

/**
 * Request parameters for updateCustomerNotificationSetting operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerNotificationSettingRequest
 */
export interface CustomerApiUpdateCustomerNotificationSettingRequest {
    /**
     * Customer Id.
     * @type {number}
     * @memberof CustomerApiUpdateCustomerNotificationSetting
     */
    readonly id: number

    /**
     * Notification settings for update.
     * @type {UpdateCustomerNotificationSettingsDto}
     * @memberof CustomerApiUpdateCustomerNotificationSetting
     */
    readonly updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto
}

/**
 * Request parameters for updateCustomerNotificationSettingForCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerNotificationSettingForCustomerRequest
 */
export interface CustomerApiUpdateCustomerNotificationSettingForCustomerRequest {
    /**
     * Notification settings for update.
     * @type {UpdateCustomerNotificationSettingsDto}
     * @memberof CustomerApiUpdateCustomerNotificationSettingForCustomer
     */
    readonly updateCustomerNotificationSettingsDto?: UpdateCustomerNotificationSettingsDto
}

/**
 * Request parameters for updateIndividualCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateIndividualCustomerRequest
 */
export interface CustomerApiUpdateIndividualCustomerRequest {
    /**
     * Record Id.
     * @type {number}
     * @memberof CustomerApiUpdateIndividualCustomer
     */
    readonly id: number

    /**
     * Individual customer data.
     * @type {UpdateIndividualCustomerDto}
     * @memberof CustomerApiUpdateIndividualCustomer
     */
    readonly updateIndividualCustomerDto?: UpdateIndividualCustomerDto
}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Archive customer.
     * @param {CustomerApiArchiveCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public archiveCustomer(requestParameters: CustomerApiArchiveCustomerRequest, options?: any) {
        return CustomerApiFp(this.configuration).archiveCustomer(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create company customer.
     * @param {CustomerApiCreateCompanyCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCompanyCustomer(requestParameters: CustomerApiCreateCompanyCustomerRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).createCompanyCustomer(requestParameters.createCompanyCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create individual customer.
     * @param {CustomerApiCreateIndividualCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createIndividualCustomer(requestParameters: CustomerApiCreateIndividualCustomerRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).createIndividualCustomer(requestParameters.createIndividualCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get individual, company, archive customer  total clunts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerTotalCounts(options?: any) {
        return CustomerApiFp(this.configuration).customerTotalCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by id.
     * @param {CustomerApiGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getById(requestParameters: CustomerApiGetByIdRequest, options?: any) {
        return CustomerApiFp(this.configuration).getById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer notification settings.
     * @param {CustomerApiGetCustomerNotificationSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerNotificationSetting(requestParameters: CustomerApiGetCustomerNotificationSettingRequest, options?: any) {
        return CustomerApiFp(this.configuration).getCustomerNotificationSetting(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer notification settings for customer user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerNotificationSettingForCustomer(options?: any) {
        return CustomerApiFp(this.configuration).getCustomerNotificationSettingForCustomer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filtered paginated customer list.
     * @param {CustomerApiGetCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomers(requestParameters: CustomerApiGetCustomersRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).getCustomers(requestParameters.id, requestParameters.name, requestParameters.isActive, requestParameters.contractCount, requestParameters.activeContractCount, requestParameters.taxNumber, requestParameters.customerType, requestParameters.address, requestParameters.birthday, requestParameters.placeOfBorn, requestParameters.mothersName, requestParameters.isArchived, requestParameters.registrationState, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get status history.
     * @param {CustomerApiGetStatusHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getStatusHistory(requestParameters: CustomerApiGetStatusHistoryRequest, options?: any) {
        return CustomerApiFp(this.configuration).getStatusHistory(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reacvivate customer.
     * @param {CustomerApiReactivateCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public reactivateCustomer(requestParameters: CustomerApiReactivateCustomerRequest, options?: any) {
        return CustomerApiFp(this.configuration).reactivateCustomer(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer.
     * @param {CustomerApiUpdateCompanyCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCompanyCustomer(requestParameters: CustomerApiUpdateCompanyCustomerRequest, options?: any) {
        return CustomerApiFp(this.configuration).updateCompanyCustomer(requestParameters.id, requestParameters.updateCompanyCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification settings.
     * @param {CustomerApiUpdateCustomerNotificationSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerNotificationSetting(requestParameters: CustomerApiUpdateCustomerNotificationSettingRequest, options?: any) {
        return CustomerApiFp(this.configuration).updateCustomerNotificationSetting(requestParameters.id, requestParameters.updateCustomerNotificationSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification settings for customer  users.
     * @param {CustomerApiUpdateCustomerNotificationSettingForCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerNotificationSettingForCustomer(requestParameters: CustomerApiUpdateCustomerNotificationSettingForCustomerRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).updateCustomerNotificationSettingForCustomer(requestParameters.updateCustomerNotificationSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer.
     * @param {CustomerApiUpdateIndividualCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateIndividualCustomer(requestParameters: CustomerApiUpdateIndividualCustomerRequest, options?: any) {
        return CustomerApiFp(this.configuration).updateIndividualCustomer(requestParameters.id, requestParameters.updateIndividualCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }
}
