/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GenerateInvoicesCommand } from '../models';
// @ts-ignore
import { InvoiceListDtoPaginatedList } from '../models';
// @ts-ignore
import { InvoicePaymentStates } from '../models';
// @ts-ignore
import { SetInvoiceGroupSentDateCommand } from '../models';
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel invoice.
         * @param {number} id Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoice: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelInvoice', 'id', id)
            const localVarPath = `/api/Invoice/{id}/Cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download invoice.
         * @param {Array<number>} [requestBody] Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice: async (requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice/Download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate incoices.
         * @param {GenerateInvoicesCommand} [generateInvoicesCommand] Range filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoices: async (generateInvoicesCommand?: GenerateInvoicesCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice/GenerateInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateInvoicesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filtered and paginated invoice list.
         * @param {number} [id] 
         * @param {string} [invoiceNumber] 
         * @param {Array<string>} [completitionDate] 
         * @param {Array<string>} [deadline] 
         * @param {Array<InvoicePaymentStates>} [invoiceStatus] 
         * @param {number} [totalAmount] 
         * @param {string} [customerName] 
         * @param {string} [contractNumber] 
         * @param {string} [contractAddress] 
         * @param {number} [customerId] 
         * @param {Array<string>} [sentDate] 
         * @param {Array<string>} [created] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (id?: number, invoiceNumber?: string, completitionDate?: Array<string>, deadline?: Array<string>, invoiceStatus?: Array<InvoicePaymentStates>, totalAmount?: number, customerName?: string, contractNumber?: string, contractAddress?: string, customerId?: number, sentDate?: Array<string>, created?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['InvoiceNumber'] = invoiceNumber;
            }

            if (completitionDate) {
                localVarQueryParameter['CompletitionDate'] = completitionDate;
            }

            if (deadline) {
                localVarQueryParameter['Deadline'] = deadline;
            }

            if (invoiceStatus) {
                localVarQueryParameter['InvoiceStatus'] = invoiceStatus;
            }

            if (totalAmount !== undefined) {
                localVarQueryParameter['TotalAmount'] = totalAmount;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['CustomerName'] = customerName;
            }

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }

            if (contractAddress !== undefined) {
                localVarQueryParameter['ContractAddress'] = contractAddress;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (sentDate) {
                localVarQueryParameter['SentDate'] = sentDate;
            }

            if (created) {
                localVarQueryParameter['Created'] = created;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pay invoice.
         * @param {number} id Invoice record id.
         * @param {string} [paidDate] Paid date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payInvoice: async (id: number, paidDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('payInvoice', 'id', id)
            const localVarPath = `/api/Invoice/{id}/Pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (paidDate !== undefined) {
                localVarQueryParameter['paidDate'] = (paidDate as any instanceof Date) ?
                    (paidDate as any).toISOString() :
                    paidDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Public download invoice with token.
         * @param {string} [token] Invoice record dowload token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDownloadInvoice: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice/PublicDownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set invoice unpaid.
         * @param {number} id Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeInvoice: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revokeInvoice', 'id', id)
            const localVarPath = `/api/Invoice/{id}/Revoke`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selected invoices sent date.
         * @param {SetInvoiceGroupSentDateCommand} [setInvoiceGroupSentDateCommand] Set selected invoices sent date command parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvoiceGroupSentDate: async (setInvoiceGroupSentDateCommand?: SetInvoiceGroupSentDateCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice/SentDateGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setInvoiceGroupSentDateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set invoice sent date.
         * @param {number} id Invoice record id.
         * @param {string} [sentDate] Invoice sent date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvoiceSentDate: async (id: number, sentDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setInvoiceSentDate', 'id', id)
            const localVarPath = `/api/Invoice/{id}/SentDate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (sentDate !== undefined) {
                localVarQueryParameter['sentDate'] = (sentDate as any instanceof Date) ?
                    (sentDate as any).toISOString() :
                    sentDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel invoice.
         * @param {number} id Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvoice(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download invoice.
         * @param {Array<number>} [requestBody] Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInvoice(requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInvoice(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate incoices.
         * @param {GenerateInvoicesCommand} [generateInvoicesCommand] Range filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateInvoices(generateInvoicesCommand?: GenerateInvoicesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInvoices(generateInvoicesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filtered and paginated invoice list.
         * @param {number} [id] 
         * @param {string} [invoiceNumber] 
         * @param {Array<string>} [completitionDate] 
         * @param {Array<string>} [deadline] 
         * @param {Array<InvoicePaymentStates>} [invoiceStatus] 
         * @param {number} [totalAmount] 
         * @param {string} [customerName] 
         * @param {string} [contractNumber] 
         * @param {string} [contractAddress] 
         * @param {number} [customerId] 
         * @param {Array<string>} [sentDate] 
         * @param {Array<string>} [created] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(id?: number, invoiceNumber?: string, completitionDate?: Array<string>, deadline?: Array<string>, invoiceStatus?: Array<InvoicePaymentStates>, totalAmount?: number, customerName?: string, contractNumber?: string, contractAddress?: string, customerId?: number, sentDate?: Array<string>, created?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceListDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(id, invoiceNumber, completitionDate, deadline, invoiceStatus, totalAmount, customerName, contractNumber, contractAddress, customerId, sentDate, created, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pay invoice.
         * @param {number} id Invoice record id.
         * @param {string} [paidDate] Paid date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payInvoice(id: number, paidDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payInvoice(id, paidDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Public download invoice with token.
         * @param {string} [token] Invoice record dowload token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDownloadInvoice(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDownloadInvoice(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set invoice unpaid.
         * @param {number} id Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeInvoice(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selected invoices sent date.
         * @param {SetInvoiceGroupSentDateCommand} [setInvoiceGroupSentDateCommand] Set selected invoices sent date command parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvoiceGroupSentDate(setInvoiceGroupSentDateCommand?: SetInvoiceGroupSentDateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvoiceGroupSentDate(setInvoiceGroupSentDateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set invoice sent date.
         * @param {number} id Invoice record id.
         * @param {string} [sentDate] Invoice sent date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvoiceSentDate(id: number, sentDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvoiceSentDate(id, sentDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel invoice.
         * @param {number} id Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoice(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.cancelInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download invoice.
         * @param {Array<number>} [requestBody] Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice(requestBody?: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.downloadInvoice(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate incoices.
         * @param {GenerateInvoicesCommand} [generateInvoicesCommand] Range filter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoices(generateInvoicesCommand?: GenerateInvoicesCommand, options?: any): AxiosPromise<void> {
            return localVarFp.generateInvoices(generateInvoicesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filtered and paginated invoice list.
         * @param {number} [id] 
         * @param {string} [invoiceNumber] 
         * @param {Array<string>} [completitionDate] 
         * @param {Array<string>} [deadline] 
         * @param {Array<InvoicePaymentStates>} [invoiceStatus] 
         * @param {number} [totalAmount] 
         * @param {string} [customerName] 
         * @param {string} [contractNumber] 
         * @param {string} [contractAddress] 
         * @param {number} [customerId] 
         * @param {Array<string>} [sentDate] 
         * @param {Array<string>} [created] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {boolean} [orderByAsc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(id?: number, invoiceNumber?: string, completitionDate?: Array<string>, deadline?: Array<string>, invoiceStatus?: Array<InvoicePaymentStates>, totalAmount?: number, customerName?: string, contractNumber?: string, contractAddress?: string, customerId?: number, sentDate?: Array<string>, created?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<InvoiceListDtoPaginatedList> {
            return localVarFp.getInvoices(id, invoiceNumber, completitionDate, deadline, invoiceStatus, totalAmount, customerName, contractNumber, contractAddress, customerId, sentDate, created, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pay invoice.
         * @param {number} id Invoice record id.
         * @param {string} [paidDate] Paid date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payInvoice(id: number, paidDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.payInvoice(id, paidDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Public download invoice with token.
         * @param {string} [token] Invoice record dowload token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDownloadInvoice(token?: string, options?: any): AxiosPromise<any> {
            return localVarFp.publicDownloadInvoice(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set invoice unpaid.
         * @param {number} id Invoice record id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeInvoice(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.revokeInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selected invoices sent date.
         * @param {SetInvoiceGroupSentDateCommand} [setInvoiceGroupSentDateCommand] Set selected invoices sent date command parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvoiceGroupSentDate(setInvoiceGroupSentDateCommand?: SetInvoiceGroupSentDateCommand, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.setInvoiceGroupSentDate(setInvoiceGroupSentDateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set invoice sent date.
         * @param {number} id Invoice record id.
         * @param {string} [sentDate] Invoice sent date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvoiceSentDate(id: number, sentDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setInvoiceSentDate(id, sentDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelInvoice operation in InvoiceApi.
 * @export
 * @interface InvoiceApiCancelInvoiceRequest
 */
export interface InvoiceApiCancelInvoiceRequest {
    /**
     * Invoice record id.
     * @type {number}
     * @memberof InvoiceApiCancelInvoice
     */
    readonly id: number
}

/**
 * Request parameters for downloadInvoice operation in InvoiceApi.
 * @export
 * @interface InvoiceApiDownloadInvoiceRequest
 */
export interface InvoiceApiDownloadInvoiceRequest {
    /**
     * Invoice record id.
     * @type {Array<number>}
     * @memberof InvoiceApiDownloadInvoice
     */
    readonly requestBody?: Array<number>
}

/**
 * Request parameters for generateInvoices operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGenerateInvoicesRequest
 */
export interface InvoiceApiGenerateInvoicesRequest {
    /**
     * Range filter.
     * @type {GenerateInvoicesCommand}
     * @memberof InvoiceApiGenerateInvoices
     */
    readonly generateInvoicesCommand?: GenerateInvoicesCommand
}

/**
 * Request parameters for getInvoices operation in InvoiceApi.
 * @export
 * @interface InvoiceApiGetInvoicesRequest
 */
export interface InvoiceApiGetInvoicesRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoices
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiGetInvoices
     */
    readonly invoiceNumber?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceApiGetInvoices
     */
    readonly completitionDate?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceApiGetInvoices
     */
    readonly deadline?: Array<string>

    /**
     * 
     * @type {Array<InvoicePaymentStates>}
     * @memberof InvoiceApiGetInvoices
     */
    readonly invoiceStatus?: Array<InvoicePaymentStates>

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoices
     */
    readonly totalAmount?: number

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiGetInvoices
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiGetInvoices
     */
    readonly contractNumber?: string

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiGetInvoices
     */
    readonly contractAddress?: string

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoices
     */
    readonly customerId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceApiGetInvoices
     */
    readonly sentDate?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceApiGetInvoices
     */
    readonly created?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoices
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceApiGetInvoices
     */
    readonly take?: number

    /**
     * 
     * @type {string}
     * @memberof InvoiceApiGetInvoices
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApiGetInvoices
     */
    readonly orderByAsc?: boolean
}

/**
 * Request parameters for payInvoice operation in InvoiceApi.
 * @export
 * @interface InvoiceApiPayInvoiceRequest
 */
export interface InvoiceApiPayInvoiceRequest {
    /**
     * Invoice record id.
     * @type {number}
     * @memberof InvoiceApiPayInvoice
     */
    readonly id: number

    /**
     * Paid date.
     * @type {string}
     * @memberof InvoiceApiPayInvoice
     */
    readonly paidDate?: string
}

/**
 * Request parameters for publicDownloadInvoice operation in InvoiceApi.
 * @export
 * @interface InvoiceApiPublicDownloadInvoiceRequest
 */
export interface InvoiceApiPublicDownloadInvoiceRequest {
    /**
     * Invoice record dowload token.
     * @type {string}
     * @memberof InvoiceApiPublicDownloadInvoice
     */
    readonly token?: string
}

/**
 * Request parameters for revokeInvoice operation in InvoiceApi.
 * @export
 * @interface InvoiceApiRevokeInvoiceRequest
 */
export interface InvoiceApiRevokeInvoiceRequest {
    /**
     * Invoice record id.
     * @type {number}
     * @memberof InvoiceApiRevokeInvoice
     */
    readonly id: number
}

/**
 * Request parameters for setInvoiceGroupSentDate operation in InvoiceApi.
 * @export
 * @interface InvoiceApiSetInvoiceGroupSentDateRequest
 */
export interface InvoiceApiSetInvoiceGroupSentDateRequest {
    /**
     * Set selected invoices sent date command parameters.
     * @type {SetInvoiceGroupSentDateCommand}
     * @memberof InvoiceApiSetInvoiceGroupSentDate
     */
    readonly setInvoiceGroupSentDateCommand?: SetInvoiceGroupSentDateCommand
}

/**
 * Request parameters for setInvoiceSentDate operation in InvoiceApi.
 * @export
 * @interface InvoiceApiSetInvoiceSentDateRequest
 */
export interface InvoiceApiSetInvoiceSentDateRequest {
    /**
     * Invoice record id.
     * @type {number}
     * @memberof InvoiceApiSetInvoiceSentDate
     */
    readonly id: number

    /**
     * Invoice sent date.
     * @type {string}
     * @memberof InvoiceApiSetInvoiceSentDate
     */
    readonly sentDate?: string
}

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Cancel invoice.
     * @param {InvoiceApiCancelInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public cancelInvoice(requestParameters: InvoiceApiCancelInvoiceRequest, options?: any) {
        return InvoiceApiFp(this.configuration).cancelInvoice(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download invoice.
     * @param {InvoiceApiDownloadInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public downloadInvoice(requestParameters: InvoiceApiDownloadInvoiceRequest = {}, options?: any) {
        return InvoiceApiFp(this.configuration).downloadInvoice(requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate incoices.
     * @param {InvoiceApiGenerateInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public generateInvoices(requestParameters: InvoiceApiGenerateInvoicesRequest = {}, options?: any) {
        return InvoiceApiFp(this.configuration).generateInvoices(requestParameters.generateInvoicesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filtered and paginated invoice list.
     * @param {InvoiceApiGetInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoices(requestParameters: InvoiceApiGetInvoicesRequest = {}, options?: any) {
        return InvoiceApiFp(this.configuration).getInvoices(requestParameters.id, requestParameters.invoiceNumber, requestParameters.completitionDate, requestParameters.deadline, requestParameters.invoiceStatus, requestParameters.totalAmount, requestParameters.customerName, requestParameters.contractNumber, requestParameters.contractAddress, requestParameters.customerId, requestParameters.sentDate, requestParameters.created, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pay invoice.
     * @param {InvoiceApiPayInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public payInvoice(requestParameters: InvoiceApiPayInvoiceRequest, options?: any) {
        return InvoiceApiFp(this.configuration).payInvoice(requestParameters.id, requestParameters.paidDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Public download invoice with token.
     * @param {InvoiceApiPublicDownloadInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public publicDownloadInvoice(requestParameters: InvoiceApiPublicDownloadInvoiceRequest = {}, options?: any) {
        return InvoiceApiFp(this.configuration).publicDownloadInvoice(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set invoice unpaid.
     * @param {InvoiceApiRevokeInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public revokeInvoice(requestParameters: InvoiceApiRevokeInvoiceRequest, options?: any) {
        return InvoiceApiFp(this.configuration).revokeInvoice(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selected invoices sent date.
     * @param {InvoiceApiSetInvoiceGroupSentDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public setInvoiceGroupSentDate(requestParameters: InvoiceApiSetInvoiceGroupSentDateRequest = {}, options?: any) {
        return InvoiceApiFp(this.configuration).setInvoiceGroupSentDate(requestParameters.setInvoiceGroupSentDateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set invoice sent date.
     * @param {InvoiceApiSetInvoiceSentDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public setInvoiceSentDate(requestParameters: InvoiceApiSetInvoiceSentDateRequest, options?: any) {
        return InvoiceApiFp(this.configuration).setInvoiceSentDate(requestParameters.id, requestParameters.sentDate, options).then((request) => request(this.axios, this.basePath));
    }
}
